import React from "react";
import Icons from "./Icons";
import Angular from "../Images/Angular.png";
import ReactLogo from "../Images/React.png";
import TailwindLogo from "../Images/tailwind.png";
import HTMLCSS from "../Images/cssbg.png";
import JS from "../Images/JSbg.png";
import TS from "../Images/TS.png";
import FigmaLogo from "../Images/figma.png";
import csharp from "../Images/Csharpbg.png";
import dotnet from "../Images/dotnetcore.png";
import mongo from "../Images/mongonew.jpeg";
import microservices from "../Images/microservices.png";
import sqlServer from "../Images/sqlServer.png";
import docker from "../Images/dockernew.png";
import prompt from "../Images/prompt-engineering.jpeg";
import python from "../Images/python.png";
import cprogram from "../Images/CProgrambg.png";

const TechCard = ({ icon, title }) => {
  return (
    <div className="border-orange-500 w-32 h-32 border-2 p-8 rounded-lg flex flex-col text-center items-center justify-center hover:bg-white hover:text-orange-500 transition duration-300 ease-in-out">
      <img src={icon} alt={title} />
      <p className="text-sm font-medium">{title}</p>
    </div>
  );
};

const TechCardMobile = ({ icon, title }) => {
  return <img src={icon} alt={title} className="w-14 h-14" />;
};


const Technologies = () => {
  return (
    <div>
      <div className="bg-black text-white py-12 h-screen flex flex-col items-center justify-center">
        <div className="flex flex-col ">
          <div className="text-3xl md:text-4xl font-bold font-mono text-center  text-orange-500">
            Skills &
          </div>
          <div className="text-2xl md:text-3xl font-bold font-mono text-center mb-4 md:mb-8">
            Technologies I Work With
          </div>

          <div className="md:flex md:flex-col gap-2 justify-center hidden ">
            <div className="flex flex-col justify-center items-center gap-2">
              <div className="font-mono">Frontend</div>
              <div className="flex gap-2">
                <TechCard
                  icon={Angular}
                  color="text-orange-500"
                  title="Angular"
                />
                <TechCard
                  icon={ReactLogo}
                  color="text-blue-400"
                  title="React"
                />
                <TechCard
                  icon={TailwindLogo}
                  color="text-yellow-400"
                  title="Tailwind CSS"
                />
                <TechCard
                  icon={HTMLCSS}
                  color="text-yellow-400"
                  title="HTML/CSS"
                />
                <TechCard
                  icon={JS}
                  color="text-yellow-400"
                  title="JavaScript"
                />
                <TechCard
                  icon={TS}
                  color="text-yellow-400"
                  title="TypeScript"
                />
                <TechCard
                  icon={FigmaLogo}
                  color="text-blue-500"
                  title="Figma"
                />
              </div>
            </div>
            <div className="flex flex-col justify-center items-center gap-2">
              <div className="font-mono">Backend</div>
              <div className="flex gap-2">
                <TechCard icon={csharp} color="text-orange-500" title="C#" />
                <TechCard
                  icon={dotnet}
                  color="text-orange-500"
                  title="ASP.NET"
                />
                <TechCard icon={mongo} color="text-blue-400" title="MongoDB" />
                <TechCard
                  icon={microservices}
                  color="text-yellow-400"
                  title="Microservices"
                />
                <TechCard
                  icon={sqlServer}
                  color="text-yellow-400"
                  title="SQLServer"
                />
                <TechCard icon={docker} color="text-blue-500" title="Docker" />
              </div>
            </div>
            <div className="flex flex-col justify-center items-center gap-2">
              <div className="font-mono">Others</div>
              <div className="flex gap-2">
                <TechCard
                  icon={prompt}
                  color="text-orange-500"
                  title="Prompt Engineering"
                />
                <TechCard icon={python} color="text-blue-400" title="Python" />
                <TechCard
                  icon={cprogram}
                  color="text-yellow-400"
                  title="C programming"
                />
              </div>
            </div>
          </div>
          <div className="md:hidden flex flex-col">
            <div className=" p-5 flex flex-col justify-center gap-4">
              <div className="text-lg font-mono text-center">Frontend</div>
              <div className="flex gap-4 justify-center">
                <TechCardMobile icon={Angular} />
                <TechCardMobile icon={ReactLogo} />
                <TechCardMobile icon={TailwindLogo} />
                <TechCardMobile icon={HTMLCSS} />
              </div>
              <div className="flex gap-4 justify-center">
                <TechCardMobile icon={JS} />
                <TechCardMobile icon={TS} />
                <TechCardMobile icon={FigmaLogo} />
              </div>
            </div>
            <div className=" p-5 flex flex-col justify-center gap-4">
              <div className="text-lg font-mono text-center">Backend</div>
              <div className="flex gap-4 justify-center">
                <TechCardMobile icon={csharp} />
                <TechCardMobile icon={dotnet} />
                <TechCardMobile icon={mongo} />
              </div>
              <div className="flex gap-4 justify-center">
                <TechCardMobile icon={microservices} />
                <TechCardMobile icon={sqlServer} />
                <TechCardMobile icon={docker} />
              </div>
            </div>
            <div className=" p-5 flex flex-col justify-center gap-4">
              <div className="text-lg font-mono text-center">Others</div>
              <div className="flex gap-4 justify-center">
                <TechCardMobile icon={prompt} />
                <TechCardMobile icon={python} />
                <TechCardMobile icon={cprogram} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Technologies;
