import React from "react";
import Icons from "./Icons";
import KKMA from "../Images/KKMA.ico";
import Liza from "../Images/LizaLogo.png";
import PlanetFMS from "../Images/planetFMS.png";

const ProjectCard = ({
  image,
  title,
  description,
  tags,
  liveLink,
}) => {
  return (
    <div className="bg-[#1C3A5D] text-white rounded-lg p-6 flex flex-col md:flex-row items-center mb-8 shadow-lg transform transition-transform hover:scale-105">
      <div className="md:w-1/3 w-full mb-4 md:mb-0 h-64 bg-white rounded-lg overflow-hidden">
        <img src={image} alt={title} className="object-fill w-full h-full" />
      </div>

      <div className="md:w-2/3 w-full md:pl-8">
        <h3 className="text-3xl font-bold mb-2">{title}</h3>
        <div className="flex flex-wrap gap-2 mb-4">
          {tags.map((tag, index) => (
            <span
              key={index}
              className="bg-gray-600 text-sm px-3 py-1 rounded-full uppercase"
            >
              {tag}
            </span>
          ))}
        </div>
        <p className="text-md mb-4">{description}</p>

        <div className="flex space-x-4">
          <a
            href={liveLink}
            className="bg-transparent border border-white px-4 py-2 rounded-full text-white hover:bg-white hover:text-black transition duration-300"
            target="_blank"
            rel="noopener noreferrer"
          >
            Live Preview
          </a>
        </div>
      </div>
    </div>
  );
};

const Freelance = () => {
  const projects = [
    {
      title: "Kuwait Kerala Muslim Association",
      description:
        "Website developed to digitize the association’s activities, including a member portal, event management, applications collection and more.",
      tags: ["Angular", "TypeScript", "Tailwind", "Firebase"],
      image: KKMA,
      liveLink: "https://www.kkmakarnataka.org/home",
    },
    {
      title: "Liza's Super Shine",
      description:
        "Website developed for a company that sells all kind of cleaning products. It helped to scale up the business and increase the sales.",
      tags: ["Angular", "TypeScript", "Tailwind", "Firebase"],
      image: Liza,
      liveLink: "https://www.lizassupershine.com/home",
    },
    {
      title: "Planet FMS UAE ",
      description:
        "A website developed for a company that provides facility management services. It helped to increase the company's online presence.",
      tags: ["React", "JavaScript", "Tailwind", "Firebase"],
      image: PlanetFMS,
      liveLink: "https://www.planetfmsuae.com/",
    },
  ];

  return (
    <div className="bg-gradient-to-r from-gray-800 via-gray-900 to-black text-white py-16 px-6">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold font-mono">Freelance work</h2>
          <p className="text-lg mt-2 font-mono">(Aug 2022 - Present)</p>
        </div>

        {projects.map((project, index) => (
          <ProjectCard
            key={index}
            image={project.image}
            title={project.title}
            description={project.description}
            tags={project.tags}
            repoLink={project.repoLink}
            liveLink={project.liveLink}
          />
        ))}
      </div>
    </div>
  );
};

export default Freelance;
