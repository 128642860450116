import React from "react";
import shazspeech from "../Images/Shaz2.jpeg";
import ParticlesComponent from "./particles";
import Icons from "./Icons";
import EducationalQualifications from "./Education";

const ProfileSection = () => {
  return (
    <div className="bg-[#0D1B2A] text-white py-16 px-6">
      <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="bg-[#1C3A5D] p-6 rounded-lg shadow-lg">
          <div className="flex justify-between mb-4">
            <div>
              <p className="text-lg mb-2">👋 Hey, I'm</p>
              <h2 className="text-4xl font-bold ">Shaz Rasheed</h2>
              <p className="text-sm mb-2">rasheedshaz03@gmail.com</p>
            </div>
            <div className="relative">
              <img
                src={shazspeech}
                alt="Shaz"
                className="rounded-full border-4 border-orange-400 w-48 h-48 object-cover"
              />
              <div className="absolute top-0 left-0 w-12 h-12 bg-[#F97316] rounded-full blur-lg opacity-50"></div>
              <div className="absolute bottom-0 right-0 w-12 h-12 bg-[#3B82F6] rounded-lg blur-lg opacity-50"></div>
            </div>
          </div>
          <p className="text-sm mb-4">
            I'm a software developer with about two years of experience, and I'm
            really passionate about creating the best software I can. My main
            goal is to become a "Top class developer," so I work hard every day
            to improve my skills and stay focused.
          </p>
          <p className="text-sm mb-4">
            Besides coding, I love public speaking and doing social service.
            I've been lucky to get several chances to speak in public and give
            back to the community. Staying disciplined is really important to
            me, and working out has played a big role in keeping me on track.
          </p>
          <p className="text-sm mb-4">
            I'm also a family person and enjoy spending time with my loved ones.
            I was born in Mangalore, did my primary schooling in Kuwait, and
            finished the rest of my education back in Mangalore. This background
            has shaped who I am today.
          </p>

          <div className="flex space-x-4 mt-4">
            <a
              href="https://www.linkedin.com/in/shaz-rasheed-hussain-74899b199/"
              className="bg-white  border border-white px-4 py-2 rounded-full flex items-center  hover:text-black transition duration-300"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icons name="linkedIn" />
            </a>
            <a
              href="https://github.com/ShazRasheed13"
              className="bg-white  border border-white px-4 py-2 rounded-full flex items-center  hover:text-black transition duration-300"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icons name="github" />
            </a>
            <a
              href="https://www.instagram.com/sha.zrasheed/"
              className="bg-white  border border-white px-4 py-2 rounded-full flex items-center  hover:text-black transition duration-300"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icons name="instagram" />
            </a>
            <a
              href="mailto:rasheedshaz03@gmail.com"
              className="bg-white border border-white px-4 py-2 rounded-full flex items-center hover:text-black transition duration-300"
              rel="noopener noreferrer"
            >
              <Icons name="email" />
            </a>
          </div>
        </div>

        <EducationalQualifications />
      </div>
    </div>
  );
};

export default ProfileSection;
