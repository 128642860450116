import "./App.css";
import ParticlesComponent from "./components/particles";
import Self from "./components/Self";
import ContactForm from "./components/ContactForm";
import Technologies from "./components/Technologies";
import Work from "./components/Work";
import Freelance from "./components/Freelance";
import Certification from "./components/Certification";
import ProfileSection from "./components/Profile";
import FloatingContactIcons from "./components/FloatingContact";

export function SkillsRolling({ image, margin }) {
  return (
    <div className={`text-white animate-moveAndRotate rounded-full w-10`}>
      <img
        src={image}
        alt="Your Name"
        className="w-full h-full object-cover rounded-full"
      />
    </div>
  );
}

export function DivisionPoint() {
  return <div className="bg-transparent h-10">Hello</div>;
}

function App() {
  return (
    <>
      <ParticlesComponent id="particles" />
      <FloatingContactIcons/>
      <Self />
      <Technologies/>
      <DivisionPoint />
      <Work/>
      <Freelance/>
      <Certification/>
      <ProfileSection/>
      <ContactForm/>
    </>
  );
}

export default App;
