import React, { useEffect, useRef, useState,useContext } from "react";
import ScrollContext from "./ScrollContext";
import emailjs from "@emailjs/browser";
import Icons from "./Icons";

export const Loader = () => {
  return (
    <div className="flex justify-center items-center">
      <div className="w-10 h-10 border-4 border-green-400 border-dashed rounded-full animate-spin"></div>
    </div>
  );
};

const ContactForm = () => {
  const { contactRef } = useContext(ScrollContext);
  const form = useRef();
  const [emailSent, setEmailSent] = useState(false);
  const [emailFailed, setSendFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    emailjs
      .sendForm("service_pzfgjfq", "template_qcw9ob9", form.current, {
        publicKey: "7rGKj9s8WP_oKZ3wt",
      })
      .then(
        () => {
          setEmailSent(true);
          setTimeout(() => {
            setEmailSent(false);
          }, 3000);
          setLoading(false);
          form.current.reset();
        },
        (error) => {
          setSendFailed(true);
          setTimeout(() => {
            setSendFailed(false);
          }, 3000);
          setLoading(false);
        }
      );
  };
  return (
    <div ref={contactRef} className="min-h-screen bg-black text-white flex flex-col items-center justify-center w-full px-4 sm:px-8">
      <div className="text-center">
        <div className="text-3xl sm:text-4xl md:text-6xl font-bold tracking-wide flex items-center justify-center">
          GET IN
          <span className="mx-2">
            <Icons name="developer" color="white" size={48} />
          </span>
          TOUCH
        </div>
      </div>

      <form
        ref={form}
        onSubmit={sendEmail}
        className="mt-8 md:mt-12 space-y-6 w-full max-w-3xl"
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
          <div className="flex flex-col">
            <label className="mb-1 sm:mb-2 text-sm sm:text-base">
              Your Name
            </label>
            <input
              type="text"
              name="name"
              className="bg-black border-b border-gray-600 focus:outline-none py-2 text-sm sm:text-base"
              placeholder="Your Name"
              required
            />
          </div>
          <div className="flex flex-col">
            <label className="mb-1 sm:mb-2 text-sm sm:text-base">
              Your Email
            </label>
            <input
              type="email"
              name="email"
              className="bg-black border-b border-gray-600 focus:outline-none py-2 text-sm sm:text-base"
              placeholder="Your Email"
              required
            />
          </div>
          <div className="flex flex-col">
            <label className="mb-1 sm:mb-2 text-sm sm:text-base">
              Phone Number
            </label>
            <input
              type="text"
              name="phone"
              className="bg-black border-b border-gray-600 focus:outline-none py-2 text-sm sm:text-base"
              placeholder="Phone Number"
              required
            />
          </div>
          <div className="flex flex-col">
            <label className="mb-1 sm:mb-2 text-sm sm:text-base">
              Your Company
            </label>
            <input
              type="text"
              name="company"
              className="bg-black border-b border-gray-600 focus:outline-none py-2 text-sm sm:text-base"
              placeholder="Your Company"
              required
            />
          </div>
        </div>

        <div className="flex flex-col">
          <label className="mb-1 sm:mb-2 text-sm sm:text-base">Message</label>
          <textarea
            name="message"
            className="bg-black border-b border-gray-600 focus:outline-none py-2 h-32 text-sm sm:text-base resize-none"
            placeholder="Drop a message..."
            required
          ></textarea>
        </div>

        {/* Button */}
        <div className="flex justify-center mt-4 mb-2 sm:mt-6">
          <button className="flex items-center bg-transparent border border-green-500 text-green-500 py-2 px-6 rounded-full hover:bg-green-500 hover:text-black transition duration-300">
            {loading ? <Loader /> : "Send Message"}
          </button>
        </div>
        {emailSent && (
          <div className="text-green-500 text-center">Message Sent</div>
        )}
        {emailFailed && (
          <div className="text-red-500 text-center">Message not Sent</div>
        )}
      </form>

      <div className="text-center text-sm md:text-base z-10 mt-20 mb-10">
        <p className="mb-4">© ALL RIGHTS RESERVED</p>
        <p className="text-gray-400">
          DEVELOPED BY{" "}
          <a href="/"  className="text-green-500 hover:underline">
            Shaz Rasheed
          </a>
        </p>
      </div>
    </div>
  );
};

export default ContactForm;
