import shaz from "../profile_picture.jpeg";
import ScrollContext from "./ScrollContext";
import { useState, useEffect, useRef, useContext } from "react";

function Self() {
  const [isVisible, setIsVisible] = useState(false);
  const { contactRef } = useContext(ScrollContext);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 2000);

    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, []);

  const scrollToContact = () => {
    contactRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className="relative h-screen flex items-center justify-center bg-transparent z-10">
      <div className="absolute inset-0 animate-uncover bg-black z-0"></div>
      <div className="relative text-center z-10">
        <div className="w-32 h-32 md:w-56 md:h-56 rounded-full overflow-hidden mx-auto mb-6 hover:scale-110 transform transition-transform duration-500 border-4 border-green-500 hover:border-red-500">
          <img
            src={shaz}
            alt="Your Name"
            className="w-full h-full object-cover transition-opacity duration-1000 ease-in-out"
          />
        </div>

        <div className="text-5xl md:text-7xl font-bold text-white font-mono animate-pulse">
          Shaz Rasheed Hussain
        </div>

        <div className="relative text-2xl md:text-4xl text-white mt-4 font-mono">
          <div className="absolute inset-0 animate-uncover bg-black z-0"></div>
          <div className="relative z-10">
            Software <span className="text-green-500">{`<Developer/>`}</span>
          </div>
        </div>
        <div className="flex flex-row justify-center gap-2 my-4 z-10">
          <button
            onClick={scrollToContact}
            className="bg-white px-8 py-3 rounded-lg font-mono hover:animate-fillGreen transition-colors duration-500 ease-in-out"
          >
            Get in touch
          </button>
        </div>
      </div>
    </div>
  );
}

export default Self;
