import React from "react";
import SJEC from "../Images/SJEC1.jpg";
import Aloy from "../Images/aloy.jpg";
import Jamiya from "../Images/Jamiya.png";

const EducationCard = ({ image, schoolName, degree, details, score }) => {
  return (
    <div className="bg-[#1E293B] text-white rounded-lg shadow-lg p-6 hover:scale-105 transition-transform duration-500 ease-in-out">
      <div className="overflow-hidden rounded-lg relative group">
        <img
          src={image}
          alt={schoolName}
          className="w-full h-48 object-fill rounded-lg transform group-hover:scale-110 transition-transform duration-500 ease-in-out"
        />
        <p className="absolute bottom-2  bg-black bg-opacity-70 px-3 py-1 text-sm rounded-lg text-center">
          {schoolName}
        </p>
      </div>
      <div className="mt-4 text-center ">
        <h2 className="text-lg font-bold mb-2">{degree}</h2>
        <p className="text-sm mb-4">{details}</p>
        <span className="text-lg font-semibold">{score}</span>
      </div>
    </div>
  );
};

const EducationalQualifications = () => {
  const educationData = [
    {
      image: SJEC,
      schoolName: "St Joseph Engineering College",
      degree: "B.E, Computer Science",
      details:
        "Affiliated to Visvesvaraya Technological University",
      score: "CGPA: 8.38",
    },
    {
      image: Aloy,
      schoolName: "St Aloysius PU College",
      degree: "PCMC",
      details:
        "Karnataka Board of Higher Secondary Education",
      score: "Percentage: 95%",
    },
    {
      image: Jamiya,
      schoolName: "Jamiya High School",
      degree: "Matriculation",
      details:
        "Board of Secondary Education, Karnataka",
      score: "Percentage: 94.88%",
    },
  ];

  return (
    <div className="flex flex-col-reverse md:flex-col">
      <div className="flex md:flex-row flex-col gap-2">
        {educationData.map((edu, index) => (
          <EducationCard
            key={index}
            image={edu.image}
            schoolName={edu.schoolName}
            degree={edu.degree}
            details={edu.details}
            score={edu.score}
          />
        ))}
      </div>
    </div>
  );
};

export default EducationalQualifications;
