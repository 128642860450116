import React from "react";
import AngularCertificate from "../Certificates/AngularCertificate.pdf";
import best from "../Certificates/best_outgoing.pdf";
import prompt from "../Certificates/promptCertificate.pdf";
import security from "../Certificates/It_sec_certificate.pdf";
import fred from "../Certificates/fredGeorgeCertificate.pdf";
import sign from "../Certificates/sign_prize_certificate.pdf";
import angular from "../Images/Angular.png";
import promptImage from "../Images/promptImage.jpeg";
import securityImage from "../Images/itsec.jpeg";
import oops from "../Images/oops.jpg";
import signLanguage from "../Images/sign-languages.jpg";
import student from "../Images/student.jpg";

const handleOpenPdf = (pdf) => {
  if (pdf) window.open(pdf, "_blank");
};

const Certificate = ({ name, from, description, pdf, imageUrl }) => {
  return (
    <div className="relative bg-gray-800 text-white p-6 rounded-lg flex flex-col md:flex-row items-center shadow-lg gap-20">
      <div
        className="absolute inset-0 bg-cover bg-right opacity-20"
        style={{ backgroundImage: `url(${imageUrl})` }}
      ></div>
      <div className="relative z-10 bg-gray-800 bg-opacity-75 p-6 rounded-lg flex flex-col md:flex-row items-center shadow-lg gap-20 w-full">
        <div>
          <h3 className="text-2xl font-bold mb-2">{name}</h3>
          <p className="text-md font-semibold mb-2">{from}</p>
          <p className="text-sm mb-4">{description}</p>
          <button
            onClick={() => {
              handleOpenPdf(pdf);
            }}
            className="bg-transparent border border-white px-4 py-2 rounded-full text-white hover:bg-white hover:text-black transition duration-300"
          >
            Certificate
          </button>
        </div>
      </div>
    </div>
  );
};

const Certification = () => {
  const certificateContent = [
    {
      name: "Advanced OOP and TDD",
      from: "Fred George BootCamp",
      description:
        "An intensive bootcamp focused on advanced Object-Oriented Programming and Test-Driven Development techniques. Its main learning was to think like a developer and write the best code in just few lines.",
      pdf: fred,
      imageUrl: oops,
    },
    {
      name: "Angular - The Complete Guide (2024 Edition)",
      from: "Maximilian Schwarzmuller - Udemy",
      description:
        "A comprehensive course covering all aspects of Angular, from basic concepts to advanced features.",
      pdf: AngularCertificate,
      imageUrl: angular,
    },
    {
      name: "The Complete Prompt Engineering for AI Bootcamp (2024)",
      from: "Mike Taylor, James Phoenix - Udemy",
      description:
        "A detailed bootcamp on prompt engineering for AI, covering various techniques and best practices. It has helped me 5X my web development speed.",
      pdf: prompt,
      imageUrl: promptImage,
    },
    {
      name: "Best Project in Technova 2022",
      from: "St Joseph Engineering College",
      description:
        "It was unique and useful project called 'Speech to sign language convertor' which helps people with hearing and speech impairments to make their lives better.",
      pdf: sign,
      imageUrl: signLanguage,
    },
    {
      name: "IT Security: Defense against the digital dark arts",
      from: "Google - Coursera",
      description:
        "A course on IT security, focusing on defense strategies against various digital threats and vulnerabilities.",
      pdf: security,
      imageUrl: securityImage,
    },
    {
      name: "Best Outgoing Student",
      from: "St Aloysius PU College",
      description:
        "Awarded for outstanding academic and extracurricular performance in II PUC as the best outgoing student.",
      pdf: best,
      imageUrl: student,
    },
  ];

  return (
    <div className="text-white py-16 px-6">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-4xl font-bold text-center mb-12 font-mono">
          Certifications & Courses
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {certificateContent.map((member, index) => (
            <Certificate
              key={index}
              name={member.name}
              role={member.role}
              description={member.description}
              pdf={member.pdf}
              imageUrl={member.imageUrl}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Certification;
