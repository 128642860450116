// ScrollContext.js
import React, { createContext, useRef } from "react";

const ScrollContext = createContext();

export const ScrollProvider = ({ children }) => {
  const contactRef = useRef(null);

  return (
    <ScrollContext.Provider value={{ contactRef }}>
      {children}
    </ScrollContext.Provider>
  );
};

export default ScrollContext;
