import React from "react";
import EG from "../eg.webp";
import Icons from "./Icons";

const Work = () => {
  return (
    <>
      <div className="text-white font-mono font-bold text-3xl md:text-4xl  text-center bg-black">
        Work Experience
      </div>
      <div className="bg-gradient-to-l from-gray-800 text-white  py-10 md:py-16 flex flex-col md:flex-row items-center justify-center px-6">
        <div className="w-full  rounded-lg md:w-1/2 mb-8 md:mb-0">
          <img
            src={EG}
            alt="EGDK India"
            className="w-full h-auto object-cover"
          />
        </div>

        <div className="w-full md:w-1/2 md:pl-12  p-2">
          <h2 className="text-4xl font-bold mb-4">EGDK India Pvt Ltd</h2>
          <p className="text-lg font-semibold mb-4">
            Full Stack Developer (Aug 2023 - Present)
          </p>
          <p className="text-md mb-6">
            I worked on the project "Estata" Rental Management System, developed
            for the people of Norway. Collaborating closely with my Scandinavian            colleagues, I played a pivotal role in driving the platform's
            development by leveraging my full-stack expertise. My focus was on
            delivering high-quality, scalable solutions that aligned with
            business goals and consistently exceeded user expectations.
          </p>
          <p className="font-bold mb-2">Key Responsibilities and Tech stack</p>
          <ul className="grid grid-cols-1 md:grid-cols-2 gap-2 text-md mb-6">
            <li>• Angular</li>
            <li>• Tailwind</li>
            <li>• C#</li>
            <li>• .NET Core</li>
            <li>• MongoDB</li>
            <li>• Microservices</li>
            <li>• Team Representation</li>
            <li>• Project Presentation</li>
          </ul>

          <button
            href="https://global.eg.dk/"
            className="bg-white text-black px-6 py-3 rounded-full flex items-center hover:bg-gray-300 transition duration-300"
          >
            <a href="https://global.eg.dk/" target="blank">
              Know more
            </a>
          </button>
        </div>
      </div>
    </>
  );
};

export default Work;
